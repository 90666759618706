import React, { useRef, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import theme from 'theme';
import { Typography, Chip, Link, Avatar, TableCell } from '@material-ui/core'
import { ReactComponent as IconSort } from 'assets/svgs/sort.svg';
import clsx from 'clsx';



const CustomTable = (props) => {
    const useStyles = makeStyles(theme => ({
        root: {
            '& table': {
                '& thead th': {
                    textAlign: 'left',
                    color: '#6E7697',
                    padding: `${theme.spacing(1, 2)} !important`,
                    borderBottom: 'none',
                    fontSize: 12,
                    lineHeight: '14px',
                    backgroundColor: '#F2F5FB',
                    '&>span': {
                        justifyContent: 'flex-start'
                    }
                },
                '& tbody': {
                    '& tr': {
                        transition: '0.5s',
                        cursor: 'pointer',
                        '& td': {
                            fontSize: 12,
                            color: theme.palette.app.primary,
                            padding: `${theme.spacing(2, 2)} !important`,
                            textAlign: 'left',
                            backgroundColor: 'white',
                            // borderTop: '1px solid ' + theme.palette.app.outlineborder,
                            // borderBottom: '1px solid ' + theme.palette.app.outlineborder,
                            // '&:first-child': {
                            //     borderLeft: '1px solid ' + theme.palette.app.outlineborder,
                            //     borderTopLeftRadius: 10,
                            //     borderBottomLeftRadius: 10
                            // },
                            // '&:last-child': {
                            //     borderRight: '1px solid ' + theme.palette.app.outlineborder,
                            //     borderTopRightRadius: 10,
                            //     borderBottomRightRadius: 10
                            // },
                        },
                        '&:nth-child(even) td': {
                            backgroundColor: '#F8F9FD'
                        },
                        '&:first-child td': {
                            [`&:nth-child(${props.startIndex ? props.startIndex : 2})`]: {
                                borderTopLeftRadius: 15,
                                overflow: 'hidden'
                            },
                            '&:last-child': {
                                borderTopRightRadius: 15,
                                overflow: 'hidden'
                            },
                        },
                        '&:last-child td': {
                            [`&:nth-child(${props.startIndex ? props.startIndex : 2})`]: {
                                borderBottomLeftRadius: 15,
                                overflow: 'hidden'
                            },
                            '&:last-child': {
                                borderBottomRightRadius: 15,
                                overflow: 'hidden'
                            },
                        },
                        '&:hover': {
                            //backgroundColor: `${theme.palette.app.primary} !important`,
                            '& td': {
                                //color: 'white !important',
                                //fill: 'white !important'
                            }
                        },
                    },
                },
            },
        },
        button: {
            color: theme.palette.app.primary,
            cursor: 'pointer',
            whiteSpace: 'nowrap'
        },
        sortIcon: {
            marginLeft: 4,
            marginTop: 4,
            height: 12
        }
    }));
    const classes = useStyles();
    const _theme = theme

    const tableColumns = props.columns
    return (
        <section className={props.overwriteStyle ? props.containerStyle : clsx(classes.root, props.containerStyle)}>
            <ThemeProvider theme={{
                ..._theme,
                overrides: {
                    ..._theme.overrides,
                    MuiToolbar: {
                        root: {
                            backgroundColor: "white",
                            borderRadius: 15
                        }
                    },
                    MUIDataTableFilterList: {
                        root: {
                            backgroundColor: "#FAFAFD",
                            margin: 0
                        }
                    },
                    MuiFormControl: {
                        root: {
                            backgroundColor: "#FAFAFD",
                            justifyContent: 'center'
                        }
                    },
                    MUIDataTableFilter: {
                        root: {
                            backgroundColor: "#FAFAFD"
                        }
                    },
                    MuiSelect: {
                        root: {
                            backgroundColor: "#FAFAFD"
                        }
                    },
                    MuiPaper: {
                        root: {
                            backgroundColor: 'transparent'
                        }
                    },
                    MUIDataTableSelectCell: {
                        root: {
                            display: "none"
                        }
                    }
                }
            }}>
                <MUIDataTable  {...props}
                    columns={tableColumns.map(item => ({
                        ...item,
                        options: {
                            ...item.options,
                            customHeadRender: ({ index, ...column }, sortColumn) => {
                                return (
                                    <TableCell key={index} onClick={() => sortColumn(index)} className={classes.button} endIcon={<IconSort />}>
                                        {item.label}
                                        {item.options && item.options.sort && <IconSort className={classes.sortIcon} />}
                                    </TableCell >
                                )
                            }
                        }
                    }))} />
            </ThemeProvider>
        </section>
    );
};

export default CustomTable;
