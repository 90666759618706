import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isDocumentFileNameCorrect } from "utils/validations";
import DeleteIcon from "@material-ui/icons/Delete";
import useGlobal from "hooks/useGlobal";
import api from "services/api";
import { store } from "react-notifications-component";
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { EcoTwoTone } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    minWidth: "50vw",
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  button: {
    minWidth: 100,
    backgroundColor: theme.palette.app.primary,
    padding: 10,
    color: "#fff",
    borderRadius: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    "&:hover": {
      backgroundColor: theme.palette.app.secondary,
    },
    "&:disabled": {
      backgroundColor: "white"
    }
  },
  file: {
    display: "none",
  },
  fileLabel: {
    display: "inline-block",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.app.primary,
    color: "#fff",
    userSelect: "none",
    cursor: "pointer",
    marginTop: theme.spacing(1.5),
  },
  files: {
    marginTop: theme.spacing(1.5),
    "& > * + *": {
      marginTop: theme.spacing(0.5),
    },
    // "& > *": {
    //   backgroundColor: "#fff",
    //   transition: "all 0.25s",

    //   "&:nth-child(2n+1)": {
    //     backgroundColor: "#eee",
    //   },
    //   "&:hover": {
    //     opacity: 0.8,
    //   },
    // },
  },
  fileRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "& > *": {
      cursor: "pointer",
    },
  },
  field: {
    marginBottom: theme.spacing(1)
  },
  cardHeader: {
    backgroundColor: theme.palette.app.primary
  },
  headerTitle: {
    color: 'white',
    fontSize: 20,
    lineHeight: '25px'
  },
}));

const DocumentUploadModal = ({ open, onCloseModal, clientId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileRef = useRef();
  const { setLoading } = useGlobal();

  const [state, setState] = useState({
    files: [],
    items: []
  });

  const onFileChange = (event) => {
    if (event.target.files.length) {
      const filteredFiles = Array.from(event.target.files);
      if (event.target.files.length !== filteredFiles.length) {
        store.addNotification({
          message: t("message.error"),
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
      setState((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...filteredFiles],
        items: [...prevState.items, ...filteredFiles.map(item => ({
          date: new Date(),
          label: '',
          type: 'RAS'
        }))]
      }));
    }
  };

  const onUpload = () => {
    const formData = new FormData();
    state.files.forEach((file, index) => {
      const filename = `${open}_${moment(state.items[index].date).format("YYYY-MM-DD")}_${state.items[index].label.replace("_", " ")}_${state.items[index].type}.pdf`
      formData.append(filename, file)
    });
    formData.append("RFID", open)
    setLoading(true);
    api
      .uploadDocuments(clientId, formData)
      .then((res) => {
        setLoading(false);
        onCloseModal(res);
        store.addNotification({
          message: t("message.success"),
          type: "success",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch(() => {
        setLoading(false);
        store.addNotification({
          message: t("message.error"),
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };
  const handleChange = (index) => (e) => {
    e.persist && e.persist()
    setState((prevState) => ({
      ...prevState,
      items: prevState.items.map((item, idx) => {
        if (idx == index) {
          return {
            ...item,
            [e.target.name]: e.target.value
          }
        }
        return item
      })
    }));
  }
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        onCloseModal([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
    >
      <Fade in={open}>
        <Card style={{ borderRadius: 10 }}>
          <CardHeader title={t("documents_upload")}
            titleTypographyProps={{ className: classes.headerTitle }}
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => {
                onCloseModal([]);
              }}>
                <CloseIcon style={{ fill: 'white' }} />
              </IconButton>
            } />
          <CardContent className={classes.modalContent}>
            <input
              className={classes.file}
              type="file"
              multiple
              accept="application/pdf"
              id="document-files"
              onChange={onFileChange}
              ref={fileRef}
            />
            <label className={classes.fileLabel} htmlFor="document-files">
              {t("select_document_files")}
            </label>
            {state.files.length > 0 && (
              <>
                <div className={classes.files}>
                  {state.files.map((file, index) => {
                    return (
                      <div key={file.name}>
                        <br />
                        <div className={classes.fileRow}>
                          <div>{file.name}</div>
                          <div
                            onClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                files: prevState.files.filter(
                                  (_, fIndex) => fIndex !== index
                                ),
                                items: prevState.items.filter(
                                  (_, fIndex) => fIndex !== index
                                ),
                              }));
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                        <TextField
                          className={classes.field}
                          label={"Label"}
                          fullWidth
                          name="label"
                          value={state.items[index].label}
                          onChange={handleChange(index)}
                          error={state.items[index].label && state.items[index].label.includes('_')}
                          helperText={state.items[index].label && state.items[index].label.includes('_') && `The label can't include "_"`}
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              className={classes.field}
                              label={"RAS/OBS"}
                              fullWidth
                              name="type"
                              value={state.items[index].type}
                              select
                              onChange={handleChange(index)}
                            >
                              <MenuItem value="RAS" >RAS</MenuItem>
                              <MenuItem value="OBS" >OBS</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                              className={classes.field}
                              autoOk={true}
                              variant="inline"
                              disableToolbar
                              label={"Date"}
                              format="DD/MM/YYYY"
                              name="date"
                              defaultValue={state.items[index].date}
                              value={state.items[index].date}
                              onChange={date => {
                                handleChange(index)({
                                  target: {
                                    name: 'date',
                                    value: date
                                  }
                                })
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              fullWidth
                              helperText={null}
                            />
                          </Grid>
                        </Grid>
                        <br />
                      </div>
                    )
                  })}
                </div>
                <br />
                <Button
                  onClick={() => {
                    setState((prevState) => ({ ...prevState, files: [] }));
                    fileRef.current.value = "";
                  }}
                  classes={{ root: classes.button }}
                >
                  {t("remove_all_files")}
                </Button>
              </>
            )}

          </CardContent>
          <Divider />
          <CardActions>
            <Button
              onClick={onUpload}
              disabled={state.files.length === 0 || state.items.find(item => !item.date || !item.label || !item.type || (item.label && item.label.includes("_")))}
              classes={{ root: classes.button }}
            >
              {t("upload")}
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

DocumentUploadModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DocumentUploadModal;
