import { Label } from "@material-ui/icons";
const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "aout",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export const isEmail = (email) => {
  const rule = /^\S+@\S+\.\S+$/;
  return email.match(rule);
};

export const isDomain = (domain) => {
  const rule = /^\S+\.\S+$/;
  return domain.match(rule);
};

export const isPhone = (ph) => {
  return true;
};

export const getDateString = (str) => {
  if (str === "") {
    return "";
  }
  const date = new Date(str);
  const ddd = date.getDate();
  return `${ddd} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getDDMMYYYYFromDate = (date) => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  return `${d < 10 ? `0${d}` : d}/${
    m < 10 ? `0${m}` : m
  }/${date.getFullYear()}`;
};

export const getHHMMFromDate = (date) => {
  const mm = date.getMinutes();
  const hh = date.getHours();
  return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}`;
};

export const getHHMMDDMMYYYYFromTimeStamp = (str) => {
  const date = new Date(str);
  const dmy = getDDMMYYYYFromDate(date);
  const hhmm = getHHMMFromDate(date);
  return `${hhmm} ${dmy}`;
};

export const get10LastLetters = (str) =>
  String(str).substr(str.length - 10, 10);

export const includes = (str1, str2) =>
  String(str1).toLowerCase().includes(String(str2).toLowerCase());

export const getListFromTreeString = (str) => {
  const treeData = JSON.parse(str);
  const result = [];
  while (true) {
    const first = treeData[0];
    treeData.shift();
    result.push(first.title);
    if (first.children) {
      for (let index = first.children.length - 1; index >= 0; index -= 1) {
        treeData.unshift(first.children[index]);
      }
    }
    if (treeData.length === 0) {
      break;
    }
  }

  return result;
};

export const includesCapitalLetter = (str) => {
  return /[A-Z]/.test(str);
};

export const includesNumber = (str) => {
  return /\d/.test(str);
};

export const isCorrectFileName = (fileName) => {
  const reg = new RegExp("^[-s0-9A-Za-z_.~]+$");
  return reg.test(fileName);
};

export const isDocumentFileNameCorrect = (fileName) => {
  const [tagId, date, label, rasObs] = String(fileName)
    .split(".pdf")[0]
    .split("_");

  if (!tagId || !date || !label || !rasObs) return false;

  if (tagId.length !== 14) return false;

  if (new Date(date).toString() === "Invalid Date") return false;

  // Label
  if (["ras", "obs"].indexOf(rasObs.toLowerCase()) < 0) return false;

  return true;
};

export const parseDocumentFileName = (fileName) => {
  const [tagId, date, label, rasObs] = String(fileName)
    .split(".pdf")[0]
    .split("/")[1]
    .split("_");
  const dateObj = new Date(date);
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
  const dateStr = [
    dateObj.getMonth() + 1,
    dateObj.getDate(),
    dateObj.getFullYear(),
  ]
    .map((e) => (e < 10 ? `0${e}` : String(e)))
    .join("/");
  return {
    fileName,
    tagId,
    date: dateStr,
    label,
    rasObs: rasObs.toUpperCase(),
  };
};

export const getPasswordSecureWarning = (password, t) => {
  if (!password) {
    return null;
  }
  if (password.length < 8) {
    return t("error.password_8");
  }
  const matchedCase = [];
  matchedCase.push("[!@#$%^&*;]"); // Special Charector
  matchedCase.push("[A-Z]"); // Uppercase Alpabates
  matchedCase.push("[0-9]"); // Numbers
  matchedCase.push("[a-z]"); // Lowercase Alphabates

  const messages = [
    "error.password_special",
    "error.password_uppercase",
    "error.password_number",
    "error.password_lowercase",
    "error.password_blacklist",
  ].map((id) => t(id));

  for (let i = 0; i < matchedCase.length; i++) {
    if (!new RegExp(matchedCase[i]).test(password)) {
      return messages[i];
    }
  }

  const passwordBlackListStr =
    "12345 123456 123456789 test1 password 12345678 zinch g_czechout asdf qwerty 1234567890 1234567 Aa123456. iloveyou 1234 abc123 111111 123123 dubsmash test princess qwertyuiop sunshine BvtTest123 11111 ashley 00000 000000 password1 monkey livetest 55555 soccer charlie asdfghjkl 654321 family michael 123321 football baseball q1w2e3r4t5y6 nicole jessica purple shadow hannah chocolate michelle daniel maggie qwerty123 hello 112233 jordan tigger 666666 987654321 superman 12345678910 summer 1q2w3e4r5t fitness bailey zxcvbnm fuckyou 121212 buster butterfly dragon jennifer amanda justin cookie basketball shopping pepper joshua  hunter ginger matthew abcd1234 taylor samantha whatever andrew 1qaz2wsx3edc thomas jasmine animoto madison 0987654321 54321 flower Password maria babygirl lovely sophie Chegg123 123456 123456790 qwerty 12345678 111111 1234567890 1234567 password 123123 0987654321 qwertyuiop mynoob 123321 666666 18atcskd2w 7777777 1q2w3e4r 654321 555555 3rjs1la7qe google 1q2w3e4r5t 123qwe zxcvbnm 1q2w3e 123456789 azerty 1234561 qwerty marseille 000000 1234567891 doudou 12345 loulou 123 password azertyuiop 12345678 soleil chouchou 1234 1234567 123123 123451 bonjour 111111 nicolas jetaime";
  if (passwordBlackListStr.split(" ").includes(password)) {
    return messages[4];
  }
  return null;
};
