import React, { Suspense, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { NavBar, TopBar } from "./components";
import { renderRoutes } from "react-router-config";
import { LoadingScreen, AuthGuard } from "components";
import { drawerWidth, drawerCloseWidth } from './components/NavBar'
import { topbar_height } from './components/TopBar'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },

  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    marginLeft: drawerWidth,
    marginTop: topbar_height,
    transition: 'all .5s ease',
  },
}));

const HomeLayout = (props) => {
  const { route } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false)
  return (
    <AuthGuard>
      <div className={classes.root}>
        <TopBar openDrawer={openDrawer}/>
        <div className={classes.container}>
          <NavBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
          <main className={classes.content} style={!openDrawer ? { marginLeft: drawerCloseWidth } : {}}>
            <Suspense fallback={<LoadingScreen />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </main>
        </div>
      </div>
    </AuthGuard>
  );
};

HomeLayout.propTypes = {
  route: PropTypes.object,
};

export default HomeLayout;
