import React, { Fragment, Suspense } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { renderRoutes } from "react-router-config";
import { GuestGuard, LoadingScreen } from "components";

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    ///backgroundImage: 'url(/images/background.jpg)',
    //backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
}));

const AuthLayout = (props) => {
  const { route } = props;
  const classes = useStyles();

  return (
    <GuestGuard>
      <main className={classes.content}>
        <Suspense fallback={<LoadingScreen />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </GuestGuard>
  );
};

AuthLayout.propTypes = {
  route: PropTypes.object,
};

export default AuthLayout;
