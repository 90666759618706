import React, { useState, useEffect } from 'react';
import {
  AppBar,
  makeStyles,
  Toolbar,
  Badge,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Button,
  Select,
  Typography
} from '@material-ui/core';
import TabImage from 'assets/images/tabimage.png';
import { drawerWidth, drawerCloseWidth } from '../NavBar'
import { useTranslation } from 'react-i18next';
import useLang from 'hooks/useLang';

export const topbar_height = 64

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    height: 64,
    minHeight: 'auto',
    boxShadow: '0 3px 3px -1px rgb(0 0 0 / 20%)',
    transition: 'all .5s ease',
    backgroundColor: 'white'
  },
  toolbar: {
    flex: 1,
    alignItems: 'flex-end',
    paddingLeft: '2%',
    paddingRight: '2%',
    backgroundColor: theme.palette.app.primary,
    marginBottom: 10,
    minHeight: 'unset !important'
  },
  logo: {
    height: 30,
  },
  divider: {
    width: 1,
    backgroundColor: '#868aa8',
    height: '40%',
    margin: '0 5px',
  },
  name: { whiteSpace: 'nowrap', color: '#868aa8' },
  middle: {
    whiteSpace: 'nowrap',
    color: '#868aa8',
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  middleLogo: {
    height: 30,
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: theme.palette.text.primary,
  },
  lng: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: theme.palette.app.primary,
  },
  langBtn: {
    color: 'white',
    border: `2px solid white`,
    borderRadius: 10,
    padding: `4px 8px`,
    marginBottom: 6,
    minWidth: 'unset',
    fontSize: 12,
    fontWeight: 'bold'
  },
  clientBtn: {
    margin: theme.spacing(0, 4, 0, 2),
    color: '#6CB570',
    backgroundColor: 'white',
    borderRadius: 10,
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    fontSize: 12,
    fontWeight: 'bold',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: 'white !important'
    }
  },
  clientBtnLabel: {
    display: 'block'
  },
  indicator: {
    width: 65,
    height: 5,
    backgroundColor: '#6CB570',
    borderRadius: 10,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 4
  },
  tabButton: {
    color: theme.palette.app.primary,
    backgroundImage: `url(${TabImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '5px 50px',
    backgroundColor: 'rgba(38, 50, 56, 0.04)'
  },
  InactivetabButton: {
    color: 'white',
    textTransform: 'none',
    marginBottom: 3,
    marginRight: theme.spacing(2)
  },
  iconbutton: {
    color: theme.palette.text.primary,
    fontWeight: '600',
    textTransform: 'none'
  },
  btnIcon: {
    fill: theme.palette.app.primary,
    width: 20,
    height: 20
  },
  badge: {
    backgroundColor: 'white',
    color: 'red',
    right: 23,
    fontSize: 8,
    top: 13,
    width: 16,
    height: 16,
    minWidth: 16
  },
  iconWrap: {
    width: 35,
    height: 35,
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clientSelect: {
    margin: theme.spacing(0, 1),
    width: 200,
    borderRadius: 8,
    backgroundColor: 'white'
  }
}));

const APAVEIMAGE = "/images/apave.png";
const LOCALEIMAGES = {
  en: "/images/en.png",
  fr: "/images/fr.png",
};

const TopBar = ({ openDrawer }) => {
  const classes = useStyles();
  const [barState, setBarState] = useState({
    anchorEl: null,
    lng: null,
  });

  const { t, i18n } = useTranslation()
  const { setLang } = useLang()
  const locale = i18n.language.substr(0, 2)


  const handleMenu = key => event => {
    setBarState(barState => ({
      ...barState,
      [key]: event.currentTarget,
    }));
  };

  const handleMenuClose = key => () => {
    setBarState(barState => ({
      ...barState,
      [key]: null,
    }));
  };

  const handleLocaleChange = lng => () => {
    setBarState(barState => ({
      ...barState,
      lng: null,
    }));
    setLang(lng)
  };
  const open = Boolean(barState.anchorEl);
  const openLng = Boolean(barState.lng);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      color="default"
      className={classes.appBar}
      style={!openDrawer ? {
        marginLeft: drawerCloseWidth,
        width: `calc(100% - ${drawerCloseWidth}px)`
      } : {}}
    >
      <Toolbar className={classes.toolbar}>
        <Button className={classes.tabButton}>
          <img src={APAVEIMAGE} alt="apave" className={classes.logo} />
        </Button>
        <Button className={classes.InactivetabButton}>
          Mission de confiance
        </Button>
        <div className={classes.middle}>

        </div>
        <div>
          <Button
            classes={{
              root: classes.clientBtn,
              label: classes.clientBtnLabel
            }}
          >
            {'Mon Rapport en Mobilité'}
            <div className={classes.indicator} />
          </Button>

          <Button aria-haspopup="true" onClick={handleMenu('lng')} className={classes.langBtn}>
            {locale}
          </Button>
          <Menu
            id="menu-lngbar"
            anchorEl={barState.lng}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openLng}
            onClose={handleMenuClose('lng')}
          >
            <MenuItem onClick={handleLocaleChange('en')}>
              <ListItemIcon>
                <img alt="img" src={LOCALEIMAGES.en} width={32} style={{ marginRight: 10 }} />
              </ListItemIcon>
              {t("home.topbar.menu.en")}
            </MenuItem>
            {/* <MenuItem>Help</MenuItem> */}
            <Divider />
            <MenuItem onClick={handleLocaleChange('fr')}>
              <ListItemIcon>
                <img alt="img" src={LOCALEIMAGES.fr} width={32} style={{ marginRight: 10 }} />
              </ListItemIcon>
              {t("home.topbar.menu.fr")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
