import React from "react";
import { ROLE_ADMIN, } from "config/constants";

import { ReactComponent as UsersIcon } from "assets/svgs/Contributors.svg";
import { ReactComponent as ClientsIcon } from "assets/svgs/Clients.svg";
import { ReactComponent as DocumentsIcon } from "assets/svgs/Documents.svg";
import { useTranslation } from "react-i18next";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { ReactComponent as IconAccount } from 'assets/svgs/menu_account.svg';
import { ReactComponent as IconLogout } from 'assets/svgs/menu_logout.svg';
import NfcIcon from '@material-ui/icons/Nfc';
import AnnouncementIcon from '@material-ui/icons/Announcement';

export default function GetMenus(role) {
  const { t } = useTranslation();
  const menus = [];
  if (role === ROLE_ADMIN) {
    menus.push({
      id: "contributor",
      text: t("navbar.users"),
      icon: <UsersIcon className="side-bar-svg" />,
      url: "/app/contributors",
      index: 0,
    });
    menus.push({
      id: "client",
      text: t("navbar.clients"),
      icon: <ClientsIcon className="side-bar-svg" />,
      url: "/app/clients",
      index: 0,
    });
  }
  menus.push({
    id: "documents",
    text: t("navbar.documents"),
    icon: <DocumentsIcon className="side-bar-svg" />,
    url: "/app/documents",
    index: 0,
  });
  menus.push({
    id: "tags",
    text: t("navbar.tags"),
    icon: <NfcIcon className="side-bar-svg" />,
    url: "/app/tags",
    index: 0,
  });
  if (role === ROLE_ADMIN) {
    menus.push({
      id: "permissions",
      text: t("navbar.permissions"),
      icon: <AccessibilityIcon className="side-bar-svg" />,
      url: "/app/permissions",
      index: 0,
    });
    menus.push({
      id: "maintenance",
      text: t("navbar.maintenance"),
      icon: <AnnouncementIcon className="side-bar-svg" />,
      url: "/app/maintenance",
      index: 0,
    });
  }
  return {
    top: [
     
    ],
    middle: menus,
    bottom: [
      {
        id: 'profile',
        text: t("navbar.profile"),
        icon: <IconAccount className="side-bar-svg" />,
        url: '/app/profile',
        index: 21,
      },
      {
        id: 'exit',
        text: t("navbar.exit"),
        icon: <IconLogout className="side-bar-svg" />,
        index: 22,
      },
    ],
  };
}
