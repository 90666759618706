import { SplashScreen } from "components";
import React, { createContext, useEffect, useReducer } from "react";

import api from "services/api";
import axiosInstance from "services/base";
import { store } from "react-notifications-component";
import useRouter from 'utils/useRouter';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  maintenance: null
};

const isValidToken = ({ expiresIn }) => {
  if (!expiresIn) {
    return false;
  }

  return new Date(expiresIn) > new Date();
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", JSON.stringify(token));
  } else {
    localStorage.removeItem("token");
    delete axiosInstance.defaults.headers.common.Authorization;
    return;
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const authorization = `${token.tokenType} ${token.accessToken}`;
      if (token) {
        config.headers["Authorization"] = authorization;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        (originalRequest.url === "/auth/admin-login" ||
          originalRequest.url === "/auth/refresh-token")
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { refreshToken, email } = token;

        if (!email)
          return Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
          );

        return axiosInstance
          .post("/auth/refresh-token", {
            email,
            refreshToken,
          })
          .then((response) => {
            const { data: res } = response;
            localStorage.setItem("token", JSON.stringify(res));
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `${res.tokenType} ${res.accessToken}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            delete axiosInstance.defaults.headers.common.Authorization;
            return Promise.reject(
              (err.response && err.response.data) || "Something went wrong"
            );
          });
      }
      Promise.reject(
        (error.response && error.response.data) || "Something went wrong"
      );
    }
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "SETMAINTENANCE": {
      const { maintenance } = action.payload;

      return {
        ...state,
        maintenance
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => { },
});
let notificationId = "";

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const router = useRouter();
  const login = async (email, password) => {
    try {
      const response = await api.login({ email, password });
      const { token, user } = response;

      setSession(token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (err) {
      router.history.push("/maintenance")
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const getMaintenance = async () => {
    api.getMaintenance({}).then(res => {
      if (res.code == 200 && res.data.length > 0) {
        dispatch({
          type: "SETMAINTENANCE",
          payload: {
            maintenance: res.data[0],
          },
        });
        if (notificationId)
          store.removeNotification(notificationId);
        if (res.data[0].active) {
          notificationId = store.addNotification({
            message: res.data[0].content,
            type: "info",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 0,
              onScreen: true,
            },
          });
        }
      }
    })
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        getMaintenance();
        const token = window.localStorage.getItem("token");
        const parsedToken = JSON.parse(token || "{}");
        if (token && isValidToken(parsedToken)) {
          setSession(parsedToken);

          const user = await api.getProfile();

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        getMaintenance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
