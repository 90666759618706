import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useLang from 'hooks/useLang';

const LOCALEIMAGES = {
  en: '/images/en.png',
  fr: '/images/fr.png',
};

const useStyles = makeStyles((theme) => ({
  container: {

  },
  button: {
    color: 'white',
    marginLeft: theme.spacing(2)
  }
}));
const SelectLanguage = () => {
  const classes = useStyles();
  const {t, i18n} = useTranslation()
  const {setLang} = useLang()

  const [barState, setBarState] = useState({
    anchorEl: null,
    lng: null,
  });
  
  const locale = i18n.language.substr(0, 2)

  const handleMenu = key => event => {
    setBarState(barState => ({
      ...barState,
      [key]: event.currentTarget,
    }));
  };

  const handleMenuClose = key => () => {
    setBarState(barState => ({
      ...barState,
      [key]: null,
    }));
  };

  const handleLocaleChange = lng => () => {
    setBarState(barState => ({
      ...barState,
      lng: null,
    }));
    setLang(lng)
  };
  const openLng = Boolean(barState.lng);
  return (
    <>
      <Button aria-haspopup="true" className={classes.button} onClick={handleMenu('lng')}>
        {t("home.topbar.menu." + locale)}
      </Button>
      <Menu
        id="menu-lngbar"
        anchorEl={barState.lng}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openLng}
        onClose={handleMenuClose('lng')}
      >
        <MenuItem onClick={handleLocaleChange('en')}>
          <ListItemIcon>
            <img alt="img" src={LOCALEIMAGES.en} width={32} style={{ marginRight: 10 }} />
          </ListItemIcon>
          {t("home.topbar.menu.en")}
        </MenuItem>
        {/* <MenuItem>Help</MenuItem> */}
        <Divider />
        <MenuItem onClick={handleLocaleChange('fr')}>
          <ListItemIcon>
            <img alt="img" src={LOCALEIMAGES.fr} width={32} style={{ marginRight: 10 }} />
          </ListItemIcon>
          {t("home.topbar.menu.fr")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default SelectLanguage;
