import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#7774',
    zIndex: 99999,
  },
}));
const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress size={30} />
    </div>
  );
};

export default Loader;
