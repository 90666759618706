import palette from 'theme/palette';

export default {
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    fill: '#000',
    backgroundColor: palette.app.primary,
    color: '#FFF',
    minWidth: 105,
    borderRadius: 100,
    '&:hover': {
      color: 'white',
      backgroundColor: '#05a969',
      fill: 'white',
    },
    '&$disabled': {
      fill: 'rgba(0, 0, 0, 0.26)',
    },
    paddingLeft: 16,
    paddingRight: 16
  },
  outlined: {
    color: palette.app.primary,
    borderColor: palette.app.primary,
    borderRadius: 100,
    paddingLeft: 16,
    paddingRight: 16
  },
  containedSizeSmall: {
    padding: '4px 16px'
  },
  outlinedSizeSmall: {
    padding: '4px 16px'
  },
  containedPrimary: {
    '&:hover': {
      color: 'white',
      backgroundColor: '#05a969',
      fill: 'white',
    }
  }
};
