import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const initialLangState = {
  lang: "fr",
};

const LangContext = createContext({
  ...initialLangState,
  setLang: () => {},
});

export const LangProvider = ({ children }) => {
  const [state, setState] = useState(initialLangState);
  const {i18n} = useTranslation()

  const setLang = (lang) => {
    setState((prevState) => ({ ...prevState, lang }));
    window.localStorage.setItem("lang", lang)
    i18n.changeLanguage(lang)
  };

  useEffect(() => {
    const initialize = () => {
      const savedLang = window.localStorage.getItem("lang");
      if (savedLang) {
        setLang(savedLang);
      }
    };

    initialize();
  // eslint-disable-next-line 
  }, []);

  return (
    <LangContext.Provider
      value={{
        ...state,
        setLang,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangContext;
