export const ROLE_ADMIN = "admin";
export const ROLE_CLIENT = "client";
export const ROLE_MANAGER = "manager";
export const ROLE_USER = "user";
export const ROLES = [ROLE_ADMIN, ROLE_CLIENT, ROLE_MANAGER, ROLE_USER];

export const EXPORT_MODE_EXCEL = 'excel';
export const EXPORT_MODE_CSV = 'csv';

export const USER_STATUS_REGISTERED = 'registered';
export const USER_STATUS_CONFIRMED = 'confirmed';
export const USER_STATUSES = [USER_STATUS_REGISTERED, USER_STATUS_CONFIRMED];
