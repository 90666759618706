import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Select, MenuItem } from "@material-ui/core";
import clsx from "classnames";
import API from "services/api";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import { ROLE_ADMIN } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: 10,
  },
}));

const ClientSelect = ({ clientId, setClientId, className, disabled }) => {
  const [clients, setClients] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth()
  const { role } = user || {}

  useEffect(() => {
    API.listClients()
      .then((res) => {
        const data = res.sort(({ company: c1 }, { company: c2 }) => {
          if (String(c1) > String(c2)) return 1;
          if (String(c1) < String(c2)) return -1;
          return 0;
        })
        setClients(
          role != ROLE_ADMIN ? data.filter(item => user.clients.includes(item.id)) : data
        );
      })
      .catch((err) => { });
  }, []);

  return (
    clients.length > 0 && (
      <Select
        className={clsx(className, classes.field)}
        fullWidth
        placeholder={t("home.user.select_company")}
        value={clientId}
        onChange={(e) => {
          const id = e.target.value;
          setClientId(id);
        }}
        disabled={disabled}
      >
        <MenuItem value={-1} disabled>
          {t("select_client_from_the_list")}
        </MenuItem>

        {clients.map((client) => (
          <MenuItem key={client.id} value={client.id}>
            {client.company}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

ClientSelect.propTypes = {
  clientId: PropTypes.any.isRequired,
  setClientId: PropTypes.func.isRequired,
  onClientsLoaded: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.any,
};

ClientSelect.defaultProps = {
  className: null,
  disabled: false,
};

export default ClientSelect;
