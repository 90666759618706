import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Paper, Typography, Grid } from '@material-ui/core';
import Transition from 'components/Transition';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5)
  },
  container: {
    marginBottom: 8,
  },
  navigationContainer: {
    borderLeft: '1px solid #e2e5ec',
  },
  navigationText: {
    fontSize: 14,
    fontWeight: 100,
    color: theme.palette.text.secondary,
    display: 'block',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  loadingContainer: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  loadingIndicator: {
    height: 2,
  },
  paper: {
    padding: 30,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 500,
    padding: 0,
    margin: 0,
  },
}));

const PageBase = ({
  children,
  minHeight,
  navigation,
  showLoadingText,
  title,
  wrapContent,
  loading,
}) => {
  const classes = useStyles();

  const content = (
    <div style={{ minHeight: minHeight || 500, height: '100%' }}>
      {loading && (
        <div>
          <div className={classes.loading}>
            <LinearProgress
              color="secondary"
              className={classes.loadingIndicator}
            />
          </div>
          {showLoadingText && (
            <div className={classes.loadingText}>loading...</div>
          )}
        </div>
      )}
      <Transition animate>{children}</Transition>
    </div>
  );

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={classes.container}
      >
        <Grid item>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {navigation && (
          <Grid item className={classes.navigationContainer}>
            <span className={classes.navigationText}>{navigation}</span>
          </Grid>
        )}
      </Grid>

      {wrapContent ? (
        <Paper className={classes.paper} square>
          {content}
        </Paper>
      ) : (
        <div>{content}</div>
      )}
    </div>
  );
};

PageBase.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  minHeight: PropTypes.number,
  navigation: PropTypes.string,
  showLoadingText: PropTypes.bool,
  title: PropTypes.any,
  wrapContent: PropTypes.bool,
};

export default PageBase;
