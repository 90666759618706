import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: "#030F40",
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400'],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#030F40',
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    fontFamily: 'OpenSans, sans-serif',
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
    light: '#F1F4F9'
  },
  divider: colors.grey[200],
  app: {
    primary: '#030F40',
    secondary: 'rgb(0, 176, 236)',
    third: '#A1A2A4',
    fourth: '#0084B8',
    fifth: '#D5D7E0',
    grey: '#E8E8E8',
    calendar: '#0FDCFD',
    danger: '#b43838',
    lightGrey: '#E0E0E0',
    divider: '#75A8BF',
    lightBlue: '#39a3e4',
    active: '#00B0EC'
  },
};
