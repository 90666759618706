import { ROLE_CLIENT } from "config/constants";
import { deleteData, getData, patchData, postData } from "./base";

const endPoints = {
  login: "/auth/admin-login",
  profile: "/users/profile",
  users: "/users",
  locations: "/locations",
  presignedUrls: "/locations/presigned-urls",
  settings: "/settings",
  documents: "/documents",
  importUsers: '/users/imports',
  documentlabels: "/document-labels",
  maintenance: "/maintenance"
};

const login = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.login, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getProfile = () =>
  new Promise((resolve, reject) => {
    getData(endPoints.profile)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

// Users
const listClients = () =>
  new Promise((resolve, reject) => {
    const params = { page: 1, perPage: 100, role: ROLE_CLIENT };
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.users + "?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const listUsers = (params) =>
  new Promise((resolve, reject) => {
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.users + "?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const listRelatedUsers = (params) =>
  new Promise((resolve, reject) => {
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.users + "/related-users?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const createUser = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.users, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getUser = (id) =>
  new Promise((resolve, reject) => {
    getData(endPoints.users + "/" + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const updateUser = (id, payload) =>
  new Promise((resolve, reject) => {
    patchData(endPoints.users + "/" + id, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const updateUserPermission = (id, payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.users + "/" + id, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const removeUser = (id) =>
  new Promise((resolve, reject) => {
    deleteData(endPoints.users + "/" + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const changePassword = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.users + "/change-password", payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const uploadDocuments = (clientId, formData) =>
  new Promise((resolve, reject) => {
    postData(endPoints.documents + "/" + clientId, formData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const generateQRCode = (clientId, formData) =>
  new Promise((resolve, reject) => {
    patchData(endPoints.documents + "/" + clientId, formData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const listDocuments = (params) =>
  new Promise((resolve, reject) => {
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.documents + "?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const listQRcodes = (params) =>
  new Promise((resolve, reject) => {
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.documents + "/qrcodes?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const removeDocument = (payload) =>
  new Promise((resolve, reject) => {
    deleteData(endPoints.documents, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const importUsers = payload =>
  new Promise((resolve, reject) => {
    postData(endPoints.importUsers, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
const createDocumentLabel = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.documentlabels, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const listDocumentLabels = (params) =>
  new Promise((resolve, reject) => {
    const query = Object.keys(params)
      .filter((key) => params[key])
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    getData(endPoints.documentlabels + "?" + query)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getMaintenance = (params) =>
  new Promise((resolve, reject) => {
    getData(endPoints.maintenance)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const updateMaintenance = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.maintenance, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
const api = {
  login,
  getProfile,
  listClients,
  changePassword,
  listUsers,
  getUser,
  updateUser,
  updateUserPermission,
  removeUser,
  createUser,
  listRelatedUsers,
  uploadDocuments,
  generateQRCode,
  listDocuments,
  listQRcodes,
  removeDocument,
  importUsers,
  createDocumentLabel,
  listDocumentLabels,
  getMaintenance,
  updateMaintenance
};

export default api;
