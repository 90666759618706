import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
import { renderRoutes } from "react-router-config";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from "./theme";

import i18n from "./i18n";
import GlobalStyle from "global-styles";
import { AuthProvider } from "contexts/JWTAuthContext";
import { LangProvider } from "contexts/LangContext";
import routes from "routes";
import { GlobalProvider } from "contexts/GlobalContext";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <BrowserRouter>
              <LangProvider>
                <AuthProvider>
                  <GlobalProvider>
                    <GlobalStyle />
                    <ReactNotification />
                    {renderRoutes(routes)}
                  </GlobalProvider>
                </AuthProvider>
              </LangProvider>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
