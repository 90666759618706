import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isDocumentFileNameCorrect } from "utils/validations";
import DeleteIcon from "@material-ui/icons/Delete";
import useGlobal from "hooks/useGlobal";
import api from "services/api";
import { store } from "react-notifications-component";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    minWidth: "50vw",
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  button: {
    minWidth: 100,
    backgroundColor: theme.palette.app.primary,
    padding: 10,
    color: "#fff",
    borderRadius: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    "&:hover": {
      backgroundColor: theme.palette.app.secondary,
    },
    "&:disabled": {
      backgroundColor: "white"
    }
  },
  file: {
    display: "none",
  },
  fileLabel: {
    display: "inline-block",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.app.primary,
    color: "#fff",
    userSelect: "none",
    cursor: "pointer",
    marginTop: theme.spacing(1.5),
  },
  files: {
    marginTop: theme.spacing(1.5),
    "& > * + *": {
      marginTop: theme.spacing(0.5),
    },
    "& > *": {
      backgroundColor: "#fff",
      transition: "all 0.25s",

      "&:nth-child(2n+1)": {
        backgroundColor: "#eee",
      },
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  fileRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "& > *": {
      cursor: "pointer",
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.app.primary
  },
  headerTitle: {
    color: 'white',
    fontSize: 20,
    lineHeight: '25px'
  },
}));

const DocumentUploadModal = ({ open, onCloseModal, clientId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileRef = useRef();
  const { setLoading } = useGlobal();

  const [state, setState] = useState({
    files: [],
  });

  const onFileChange = (event) => {
    if (event.target.files.length) {
      const filteredFiles = Array.from(event.target.files).filter((file) =>
        isDocumentFileNameCorrect(file.name)
      );
      if (event.target.files.length !== filteredFiles.length) {
        store.addNotification({
          message: t("message.error"),
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
      setState((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...filteredFiles],
      }));
    }
  };

  const onUpload = () => {
    const formData = new FormData();
    state.files.forEach((file) => formData.append(file.name, file));
    setLoading(true);
    api
      .uploadDocuments(clientId, formData)
      .then((res) => {
        setLoading(false);
        onCloseModal(res);
        store.addNotification({
          message: t("message.success"),
          type: "success",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch(() => {
        setLoading(false);
        store.addNotification({
          message: t("message.error"),
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        onCloseModal([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
    >
      <Fade in={open}>
        <Card style={{ borderRadius: 10 }}>
          <CardHeader title={t("documents_upload")}
            titleTypographyProps={{ className: classes.headerTitle }}
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => {
                onCloseModal([]);
              }}>
                <CloseIcon style={{ fill: 'white' }} />
              </IconButton>
            } />
          <CardContent className={classes.modalContent}>
            <Typography>
              {t("error.document_name_format")}:
              04442a32246982_2020-02-04_obTest_RAS.pdf
              <br />
            </Typography>
            <input
              className={classes.file}
              type="file"
              multiple
              accept="application/pdf"
              id="document-files"
              onChange={onFileChange}
              ref={fileRef}
            />
            <label className={classes.fileLabel} htmlFor="document-files">
              {t("select_document_files")}
            </label>
            {state.files.length > 0 && (
              <>
                <div className={classes.files}>
                  {state.files.map((file, index) => (
                    <div key={file.name} className={classes.fileRow}>
                      <div>{file.name}</div>
                      <div
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            files: prevState.files.filter(
                              (_, fIndex) => fIndex !== index
                            ),
                          }));
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))}
                </div>
                <br />
                <Button
                  onClick={() => {
                    setState((prevState) => ({ ...prevState, files: [] }));
                    fileRef.current.value = "";
                  }}
                  classes={{ root: classes.button }}
                >
                  {t("remove_all_files")}
                </Button>
              </>
            )}
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              onClick={onUpload}
              disabled={state.files.length === 0}
              classes={{ root: classes.button }}
            >
              {t("upload")}
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

DocumentUploadModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DocumentUploadModal;
